'use client';

import React, { useState, useEffect, useMemo } from 'react';
import { Box, Typography, TextField, Button, IconButton, Grid, CircularProgress, Checkbox, Alert, Select, MenuItem, FormControlLabel, Switch } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { Venue, Section } from '../../../../shared/models';
import { createVenueSectionPictures, deleteVenueSectionPicture } from '../../lib/firebase/venues';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import { getVenueById } from '../../lib/firebase/venues';
import { normalizeImageUrl } from '../../lib/util';

interface VenueSectionsProps {
    data: Partial<Venue>;
    onNext: (venueData: Partial<Venue>) => void;
    missingFields?: string[];
}

const VenueSections: React.FC<VenueSectionsProps> = ({ data, onNext, missingFields }) => {
    // Initialize venue data and ensure PrivacyLevel defaults to 'private' for all sections
    const initialVenueData = useMemo(() => {
        const venueData = { ...data };

        // Set PrivacyLevel to 'private' for any sections that don't have it defined
        if (venueData.Sections) {
            Object.entries(venueData.Sections).forEach(([sectionId, section]) => {
                if (section.PrivacyLevel === undefined) {
                    venueData.Sections![sectionId] = {
                        ...section,
                        PrivacyLevel: 'private'
                    };
                }
            });
        }

        return venueData;
    }, [data]);

    const [venue, setVenue] = useState<Partial<Venue>>(initialVenueData);
    const [uploadProgress, setUploadProgress] = useState<{ [key: string]: number }>({});
    const [error, setError] = useState<string | null>(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [hasChanges, setHasChanges] = useState(false);

    // Effect to detect changes in venue data compared to initial data
    useEffect(() => {
        // Deep comparison of venue and initialVenueData
        const venueStr = JSON.stringify(venue);
        const initialVenueStr = JSON.stringify(initialVenueData);
        setHasChanges(venueStr !== initialVenueStr);
    }, [venue, initialVenueData]);

    const addSection = () => {
        const newSectionId = `section_${Date.now()}_${Math.random().toString(36).substr(2, 9)}`;
        setVenue(prevVenue => ({
            ...prevVenue,
            Sections: {
                ...prevVenue.Sections,
                [newSectionId]: {
                    Name: '',
                    Capacity: 0,
                    Description: '',
                    Images: [],
                    OverlapsAllOtherSections: false,
                    CheckoutPageDescription: '',
                    PrivacyLevel: 'private',
                },
            },
        }));
    };

    const removeSection = (sectionId: string) => {
        const { [sectionId]: _, ...remainingSections } = venue.Sections || {};
        setVenue(prevVenue => ({
            ...prevVenue,
            Sections: remainingSections,
        }));
    };

    const updateSection = (sectionId: string, field: keyof Section, value: any) => {
        setVenue(prevVenue => {
            const sections = prevVenue.Sections || {};
            const section = sections[sectionId] || {
                Name: '',
                Capacity: 0,
                Description: '',
                Images: [],
                OverlapsAllOtherSections: false,
                CheckoutPageDescription: '',
                PrivacyLevel: 'private',
            } as Section;

            return {
                ...prevVenue,
                Sections: {
                    ...sections,
                    [sectionId]: {
                        ...section,
                        [field]: value,
                    },
                },
            };
        });
    };

    const handleNext = () => {
        if (!venue.Sections || Object.keys(venue.Sections).length === 0) {
            setError("Please add at least one section before continuing.");
            return;
        }
        setError(null);
        onNext(venue);
    };

    const handleFileUpload = async (sectionId: string, files: FileList) => {
        if (!venue.ID) {
            console.error('Venue ID is not set');
            return;
        }

        try {
            setUploadProgress(prev => ({
                ...prev,
                [sectionId]: 0,
            }));

            // Upload images - this function handles renaming and updating Firestore
            await createVenueSectionPictures(
                venue.ID,
                sectionId,
                Array.from(files),
                (progress) => {
                    setUploadProgress(prev => ({
                        ...prev,
                        [sectionId]: progress,
                    }));
                }
            );

            // Reset upload progress
            setUploadProgress(prev => ({
                ...prev,
                [sectionId]: 0,
            }));

            // Fetch the updated venue data from Firestore to get the correct image paths
            try {
                const updatedVenue = await getVenueById(venue.ID);
                if (updatedVenue) {
                    // Update our local state with the latest data from Firestore
                    setVenue(prev => ({
                        ...prev,
                        Sections: updatedVenue.Sections
                    }));
                }
            } catch (error) {
                console.error('Error fetching updated venue data:', error);
            }
        } catch (error) {
            console.error('Error uploading files:', error);
            setUploadProgress(prev => ({
                ...prev,
                [sectionId]: 0,
            }));
        }
    };

    const removeImage = async (sectionId: string, imageUrl: string, index: number) => {
        try {
            // First, delete the image from Firebase Storage
            const success = await deleteVenueSectionPicture(venue.ID, sectionId, imageUrl);

            if (!success) {
                console.warn('Could not delete image from storage, but will remove from venue data');
            }

            // Remove from section.Images array in local state
            updateSection(sectionId, 'Images', (venue.Sections?.[sectionId].Images || []).filter(url => url !== imageUrl));
        } catch (error) {
            console.error('Error removing image:', error);
            // Still remove from local state even if storage deletion fails
            updateSection(sectionId, 'Images', (venue.Sections?.[sectionId].Images || []).filter(url => url !== imageUrl));
        }
    };

    // Clean up object URLs when component unmounts
    useEffect(() => {
        return () => {
            // No cleanup needed anymore since we removed imagePreviewUrls
        };
    }, []);

    // Common TextField styling that can be reused
    const textFieldSx = {
        '& .MuiOutlinedInput-root': {
            backgroundColor: '#1a1a38', // Darker background for better contrast
            '& fieldset': { borderColor: '#6c63ff' },
            '&:hover fieldset': { borderColor: '#8f7fff' },
            '&.Mui-focused fieldset': { borderColor: '#a594ff' },
        },
        '& .MuiInputLabel-root': {
            color: '#d1c4e9',
            '&.Mui-focused': { color: '#8f7fff' }
        },
        '& .MuiInputBase-input': {
            color: '#ffffff',
            '&::placeholder': { color: '#d1c4e9' },
            fontSize: '1rem',
            lineHeight: '1.5',
        },
        mb: 2,
    }

    // Helper functions for image deduplication
    const getUniqueNormalizedUrls = (urls: string[] | undefined | null): string[] => {
        if (!urls || !Array.isArray(urls)) return [];

        const seen = new Set<string>();
        return urls.map(url => normalizeImageUrl(url))
            .filter(normalizedUrl => {
                if (seen.has(normalizedUrl)) return false;
                seen.add(normalizedUrl);
                return true;
            });
    };

    const getAllVariants = (normalizedUrl: string, urls: string[]): string[] => {
        return urls.filter(url => normalizeImageUrl(url) === normalizedUrl);
    };

    const getBestImageUrl = (normalizedUrl: string, urls: string[]): string => {
        const variants = getAllVariants(normalizedUrl, urls);
        // Prefer 1600x1600 if available, then 800x800, then 400x400, or just the first variant
        return variants.find(url => url.includes('_1600x1600')) ||
            variants.find(url => url.includes('_800x800')) ||
            variants.find(url => url.includes('_400x400')) ||
            variants[0];
    };

    return (
        <Box sx={{ width: '100%', mt: 4, color: '#ffffff' }}>
            <Typography variant="h5" sx={{ color: '#d1c4e9', mb: 3 }}>
                Define Venue Sections
            </Typography>
            <Typography variant="subtitle1" sx={{ color: '#a094b7', mb: 2 }}>
                Define the sections people can rent out. Also you can define sections that are not private like tables or a spot at the bar. Later you can define prices for each section.
            </Typography>
            <Grid container spacing={3}>
                {Object.entries(venue.Sections || {}).map(([sectionId, section]) => (
                    <Grid item xs={12} md={6} key={sectionId}>
                        <Box sx={{
                            mb: 4,
                            p: 2,
                            backgroundColor: '#232342',
                            borderRadius: 2,
                            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                            border: '1px solid rgba(255, 255, 255, 0.12)'
                        }}>
                            <Grid container spacing={3} alignItems="center">
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        fullWidth
                                        label="Section Name"
                                        value={section.Name}
                                        onChange={(e) => updateSection(sectionId, 'Name', e.target.value)}
                                        sx={textFieldSx}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        fullWidth
                                        type="number"
                                        label="Capacity"
                                        value={section.Capacity}
                                        onChange={(e) => updateSection(sectionId, 'Capacity', parseInt(e.target.value))}
                                        sx={textFieldSx}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        multiline
                                        rows={3}
                                        label="Description"
                                        value={section.Description}
                                        onChange={(e) => updateSection(sectionId, 'Description', e.target.value)}
                                        sx={textFieldSx}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        multiline
                                        rows={4}
                                        label="Checkout Page Description"
                                        value={section.CheckoutPageDescription}
                                        onChange={(e) => updateSection(sectionId, 'CheckoutPageDescription', e.target.value)}
                                        sx={textFieldSx}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="subtitle2" sx={{ color: '#d1c4e9', mb: 1 }}>
                                        Privacy Level
                                    </Typography>
                                    <Typography variant="caption" sx={{ color: '#a094b7', mb: 2, display: 'block' }}>
                                        Select the privacy level for this section
                                    </Typography>
                                    <Select
                                        fullWidth
                                        value={section.PrivacyLevel || 'private'}
                                        onChange={(e) => updateSection(sectionId, 'PrivacyLevel', e.target.value)}
                                        MenuProps={{
                                            PaperProps: {
                                                sx: {
                                                    backgroundColor: '#232342',
                                                    border: '1px solid #6c63ff',
                                                    '& .MuiMenuItem-root': {
                                                        padding: '12px 16px',
                                                        borderBottom: '1px solid rgba(255, 255, 255, 0.08)',
                                                        '&:last-child': {
                                                            borderBottom: 'none',
                                                        },
                                                        '&:hover': {
                                                            backgroundColor: 'rgba(108, 99, 255, 0.1)',
                                                        },
                                                        '&.Mui-selected': {
                                                            backgroundColor: 'rgba(108, 99, 255, 0.2)',
                                                            '&:hover': {
                                                                backgroundColor: 'rgba(108, 99, 255, 0.3)',
                                                            },
                                                        },
                                                    },
                                                },
                                            },
                                        }}
                                        sx={{
                                            backgroundColor: '#1a1a38',
                                            color: '#ffffff',
                                            mb: 2,
                                            height: '56px',
                                            '& .MuiSelect-icon': {
                                                color: '#6c63ff',
                                            },
                                            '& .MuiOutlinedInput-notchedOutline': {
                                                borderColor: '#6c63ff',
                                            },
                                            '&:hover .MuiOutlinedInput-notchedOutline': {
                                                borderColor: '#8f7fff',
                                            },
                                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                borderColor: '#a594ff',
                                            },
                                        }}
                                    >
                                        <MenuItem value="private" sx={{ my: 0.5 }}>
                                            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                                <Typography variant="body1" sx={{ color: '#ffffff', fontWeight: 600 }}>Private</Typography>
                                                <Typography variant="caption" sx={{ color: '#d1c4e9', mt: 0.5 }}>
                                                    Only accessible to the booking party
                                                </Typography>
                                            </Box>
                                        </MenuItem>
                                        <MenuItem value="semi-private" sx={{ my: 0.5 }}>
                                            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                                <Typography variant="body1" sx={{ color: '#ffffff', fontWeight: 600 }}>Semi-Private</Typography>
                                                <Typography variant="caption" sx={{ color: '#d1c4e9', mt: 0.5 }}>
                                                    Limited access to others
                                                </Typography>
                                            </Box>
                                        </MenuItem>
                                        <MenuItem value="non-private" sx={{ my: 0.5 }}>
                                            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                                <Typography variant="body1" sx={{ color: '#ffffff', fontWeight: 600 }}>Non-Private</Typography>
                                                <Typography variant="caption" sx={{ color: '#d1c4e9', mt: 0.5 }}>
                                                    General access area
                                                </Typography>
                                            </Box>
                                        </MenuItem>
                                    </Select>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="subtitle2" sx={{ color: '#d1c4e9', mb: 2 }}>
                                        Section Type
                                    </Typography>
                                    <Box sx={{
                                        display: 'flex',
                                        gap: 2,
                                        flexDirection: 'column'
                                    }}>
                                        <Box
                                            onClick={() => {
                                                updateSection(sectionId, 'OverlapsAllOtherSections', false);
                                                updateSection(sectionId, 'IsParentOfSectionIDs', []);
                                            }}
                                            sx={{
                                                p: 2,
                                                borderRadius: 2,
                                                cursor: 'pointer',
                                                border: '2px solid',
                                                borderColor: (!section.OverlapsAllOtherSections && !section.IsParentOfSectionIDs?.length) ? '#6c63ff' : '#3f3f7a',
                                                backgroundColor: '#2c2c54',
                                                '&:hover': {
                                                    borderColor: '#8f7fff',
                                                    backgroundColor: '#343463'
                                                }
                                            }}
                                        >
                                            <Typography variant="subtitle1" sx={{ color: '#ffffff', mb: 1 }}>
                                                Standard Section
                                            </Typography>
                                            <Typography variant="body2" sx={{ color: '#a094b7' }}>
                                                Can be booked independently from other sections
                                            </Typography>
                                        </Box>

                                        <Box
                                            onClick={() => {
                                                updateSection(sectionId, 'OverlapsAllOtherSections', true);
                                                updateSection(sectionId, 'IsParentOfSectionIDs', []);
                                            }}
                                            sx={{
                                                p: 2,
                                                borderRadius: 2,
                                                cursor: 'pointer',
                                                border: '2px solid',
                                                borderColor: section.OverlapsAllOtherSections ? '#6c63ff' : '#3f3f7a',
                                                backgroundColor: '#2c2c54',
                                                '&:hover': {
                                                    borderColor: '#8f7fff',
                                                    backgroundColor: '#343463'
                                                }
                                            }}
                                        >
                                            <Typography variant="subtitle1" sx={{ color: '#ffffff', mb: 1 }}>
                                                Full Venue
                                            </Typography>
                                            <Typography variant="body2" sx={{ color: '#a094b7' }}>
                                                Books the entire venue (includes all other sections)
                                            </Typography>
                                        </Box>

                                        {/* Only show Combined Section option if there are other sections */}
                                        {Object.keys(venue.Sections || {}).length > 1 && (
                                            <Box
                                                onClick={() => {
                                                    const isCurrentlyCombined = section.IsParentOfSectionIDs?.length > 0;
                                                    updateSection(sectionId, 'OverlapsAllOtherSections', false);
                                                    updateSection(sectionId, 'IsParentOfSectionIDs', isCurrentlyCombined ? [] : []);
                                                }}
                                                sx={{
                                                    p: 2,
                                                    borderRadius: 2,
                                                    cursor: 'pointer',
                                                    border: '2px solid',
                                                    borderColor: section.IsParentOfSectionIDs?.length > 0 ? '#6c63ff' : '#3f3f7a',
                                                    backgroundColor: '#2c2c54',
                                                    '&:hover': {
                                                        borderColor: '#8f7fff',
                                                        backgroundColor: '#343463'
                                                    }
                                                }}
                                            >
                                                <Typography variant="subtitle1" sx={{ color: '#ffffff', mb: 1 }}>
                                                    Combined Section
                                                </Typography>
                                                <Typography variant="body2" sx={{ color: '#a094b7' }}>
                                                    If this section is combined with others, then if it's booked the other section will be booked as well.
                                                </Typography>
                                            </Box>
                                        )}

                                        {!section.OverlapsAllOtherSections && section.IsParentOfSectionIDs?.length >= 0 && (
                                            <Box sx={{
                                                pl: 2,
                                                mt: 1,
                                                borderLeft: '2px solid #6c63ff',
                                            }}>
                                                <Typography variant="subtitle2" sx={{ color: '#d1c4e9', mb: 2 }}>
                                                    Select sections to combine with
                                                </Typography>
                                                <Box sx={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    gap: 1,
                                                }}>
                                                    {Object.entries(venue.Sections || {})
                                                        .filter(([id]) => id !== sectionId)
                                                        .map(([id, otherSection]) => (
                                                            <Box
                                                                key={id}
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    const currentIds = section.IsParentOfSectionIDs || [];
                                                                    const newIds = currentIds.includes(id)
                                                                        ? currentIds.filter(sid => sid !== id)
                                                                        : [...currentIds, id];
                                                                    updateSection(sectionId, 'IsParentOfSectionIDs', newIds);
                                                                }}
                                                                sx={{
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    p: 1.5,
                                                                    borderRadius: 1,
                                                                    cursor: 'pointer',
                                                                    backgroundColor: section.IsParentOfSectionIDs?.includes(id)
                                                                        ? 'rgba(108, 99, 255, 0.2)'
                                                                        : '#2c2c54',
                                                                    border: '1px solid',
                                                                    borderColor: section.IsParentOfSectionIDs?.includes(id)
                                                                        ? '#6c63ff'
                                                                        : '#3f3f7a',
                                                                    '&:hover': {
                                                                        backgroundColor: 'rgba(108, 99, 255, 0.1)',
                                                                        borderColor: '#8f7fff'
                                                                    },
                                                                    transition: 'all 0.2s ease'
                                                                }}
                                                            >
                                                                <Checkbox
                                                                    checked={section.IsParentOfSectionIDs?.includes(id)}
                                                                    onChange={(e) => {
                                                                        e.stopPropagation();
                                                                        const currentIds = section.IsParentOfSectionIDs || [];
                                                                        const newIds = currentIds.includes(id)
                                                                            ? currentIds.filter(sid => sid !== id)
                                                                            : [...currentIds, id];
                                                                        updateSection(sectionId, 'IsParentOfSectionIDs', newIds);
                                                                    }}
                                                                    sx={{
                                                                        color: '#6c63ff',
                                                                        '&.Mui-checked': {
                                                                            color: '#6c63ff',
                                                                        },
                                                                    }}
                                                                />
                                                                <Box>
                                                                    <Typography variant="subtitle2" sx={{ color: '#ffffff' }}>
                                                                        {otherSection.Name || 'Unnamed Section'}
                                                                    </Typography>
                                                                    <Typography variant="caption" sx={{ color: '#a094b7' }}>
                                                                        Capacity: {otherSection.Capacity || 0}
                                                                    </Typography>
                                                                </Box>
                                                            </Box>
                                                        ))}
                                                </Box>
                                            </Box>
                                        )}
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box sx={{ mb: 2 }}>
                                        <input
                                            type="file"
                                            multiple
                                            onChange={(e) => handleFileUpload(sectionId, e.target.files!)}
                                            style={{ display: 'none' }}
                                            id={`file-upload-${sectionId}`}
                                            accept="image/*"
                                            disabled={uploadProgress[sectionId] > 0 && uploadProgress[sectionId] < 100}
                                        />
                                        <label htmlFor={`file-upload-${sectionId}`}>
                                            <div className="flex items-center justify-center border-2 border-dashed border-[#6c63ff] rounded-lg p-6 cursor-pointer hover:bg-[rgba(108,99,255,0.1)] transition-colors">
                                                <div className="text-center">
                                                    <AddPhotoAlternateIcon sx={{ fontSize: 32, color: '#6c63ff' }} />
                                                    <Typography sx={{ color: '#6c63ff', mt: 1 }}>
                                                        Add Photos
                                                    </Typography>
                                                    {uploadProgress[sectionId] > 0 && uploadProgress[sectionId] < 100 && (
                                                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mt: 1 }}>
                                                            <CircularProgress
                                                                variant="determinate"
                                                                value={uploadProgress[sectionId]}
                                                                size={24}
                                                                sx={{ color: '#6c63ff' }}
                                                            />
                                                            <Typography variant="body2" sx={{ ml: 1, color: '#a094b7' }}>
                                                                {`${Math.round(uploadProgress[sectionId])}%`}
                                                            </Typography>
                                                        </Box>
                                                    )}
                                                </div>
                                            </div>
                                        </label>
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    {/* Section Images with deduplication */}
                                    {section.Images && section.Images.length > 0 && (
                                        <Box sx={{ mt: 2 }}>
                                            <Typography variant="subtitle2" sx={{ color: '#d1c4e9', mb: 1 }}>
                                                Section Images
                                            </Typography>
                                            <Grid container spacing={1}>
                                                {getUniqueNormalizedUrls(section.Images).map((normalizedUrl, index) => {
                                                    const bestImageUrl = getBestImageUrl(normalizedUrl, section.Images || []);
                                                    return (
                                                        <Grid item xs={4} sm={3} key={index}>
                                                            <Box
                                                                sx={{
                                                                    position: 'relative',
                                                                    height: 0,
                                                                    pb: '100%', // 1:1 aspect ratio
                                                                    borderRadius: 1,
                                                                    overflow: 'hidden',
                                                                    border: '1px solid rgba(255, 255, 255, 0.12)',
                                                                }}
                                                            >
                                                                <Box
                                                                    component="img"
                                                                    src={bestImageUrl.startsWith('http') ? bestImageUrl : `https://getlitty.imgix.net${bestImageUrl}`}
                                                                    alt={`Section Image ${index + 1}`}
                                                                    sx={{
                                                                        position: 'absolute',
                                                                        top: 0,
                                                                        left: 0,
                                                                        width: '100%',
                                                                        height: '100%',
                                                                        objectFit: 'cover',
                                                                    }}
                                                                />
                                                                <IconButton
                                                                    size="small"
                                                                    onClick={() => {
                                                                        // Delete all variants of this image
                                                                        getAllVariants(normalizedUrl, section.Images || []).forEach(variant => {
                                                                            removeImage(sectionId, variant, -1);
                                                                        });
                                                                    }}
                                                                    sx={{
                                                                        position: 'absolute',
                                                                        top: 4,
                                                                        right: 4,
                                                                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                                                        color: '#ffffff',
                                                                        p: 0.5,
                                                                        '&:hover': {
                                                                            backgroundColor: 'rgba(255, 0, 0, 0.7)',
                                                                        },
                                                                    }}
                                                                >
                                                                    <DeleteIcon fontSize="small" />
                                                                </IconButton>
                                                            </Box>
                                                        </Grid>
                                                    );
                                                })}
                                            </Grid>
                                        </Box>
                                    )}
                                </Grid>
                                <Grid item xs={12} sm={12} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                                    <IconButton onClick={() => removeSection(sectionId)} sx={{ color: '#ff6b6b', '&:hover': { backgroundColor: 'rgba(255, 107, 107, 0.1)' } }}>
                                        <DeleteIcon />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                ))}
            </Grid>
            <Button
                startIcon={<AddIcon />}
                onClick={addSection}
                sx={{
                    color: '#d1c4e9',
                    borderColor: '#d1c4e9',
                    '&:hover': { backgroundColor: 'rgba(209, 196, 233, 0.1)' },
                    mt: 2,
                    mb: 2,
                }}
                variant="outlined"
            >
                Add Section
            </Button>
            {error && (
                <Alert severity="error" sx={{ mt: 2, mb: 2 }}>
                    {error}
                </Alert>
            )}
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                {hasChanges && (
                    <Button
                        onClick={handleNext}
                        disabled={!venue.Sections || Object.keys(venue.Sections).length === 0}
                        sx={{
                            backgroundColor: '#6c63ff',
                            color: '#ffffff',
                            '&:hover': { backgroundColor: '#8f7fff' },
                            '&.Mui-disabled': {
                                backgroundColor: 'rgba(108, 99, 255, 0.3)',
                                color: 'rgba(255, 255, 255, 0.4)',
                            },
                            minWidth: '160px',
                            fontWeight: 'bold',
                        }}
                        variant="contained"
                    >
                        Save and Continue
                    </Button>
                )}
            </Box>
        </Box>
    );
};

export default VenueSections;
